:root {
  --main: #0b2239;
}
*{
  scroll-behavior: smooth
}

@import url("typography.css");

.satoshi {
  font-family: Satoshi !important;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ebf1ff;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--main);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #9cd5ff;
  /* background: var(--color5); */
}

.loginbg {
  background: linear-gradient(180deg, #021c2f 0%, #0592f6 84.79%, #021c2f 100%);
}
.myBorder {
  border: 0.4px solid rgba(255, 255, 255, 0.2);
}
h1,
h2,
h3,
h4,
h5,
h6,
span,
small,
p {
  padding: 0;
}
.custom-list {
  list-style-type: disc;
  color: #1c1c1c;
}
.noScroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.smallText {
  @apply tw-text-sm tw-text-main satoshi tw-font-medium;
}
.tableText {
  @apply tw-text-sm tw-text-main satoshi tw-p-3 tw-font-normal;
}

pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
